:root {
    --side-bar-text-color: white;
    --theme-color: #2b3379;

    --side-bar: #2b3379;
    --card-color: #fff;
    --main-text-color: #444;
}

.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    background: #1c92d2; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #5bc2f9,
        #f2fcfe
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #71c5f2,
        #f2fcfe
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.welcome .wrapper {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    /* height: 200px; */
    justify-content: center;
    width: 80%;
}

.welcome .wrapper .welcome-title {
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.welcome .wrapper .welcome-title h1 {
}

.welcome .wrapper .welcome-title img {
    max-width: 300px;
}

.welcome .wrapper {
}

.welcome .wrapper .message {
    margin-top: 50px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-align: justify;
}

.welcome .wrapper .sponsors {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 40px;
}

.welcome .wrapper .sponsors .sponsor {
    width: 200px;
    height: 200px;
    background-color: rebeccapurple;
}

.theme-color {
    color: var(--theme-color) !important;
}

.row {
    flex: 1;
    gap: 10px;
}
.col {
    flex: 1;
    gap: 10px;
}
.flex-2 {
    flex: 2;
}

.flex-1 {
    flex: 1;
}
.main-section {
    display: flex;
    flex: 1;
    background-color: #fff;
    min-height: 100vh;
}

.main-section .container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main-section .container .header {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    flex-wrap: wrap;
}

/* .graphs{
    background-color: red !important;
    max-width: 100%;
} */

.main-section .container .body {
    /* background-color: blue; */
    /* flex: 6; */
    display: flex;
    flex-direction: row;
    margin-left: 300px;
    min-height: 100vh;
}

.public-side-bar {
    left: 0;
    top: 0;
    position: fixed;
    width: 300px;
    height: 100vh;
    z-index: 3;
    background-color: var(--side-bar);
    /* flex: 2; */
    /* overflow: scroll; */
}
.public-side-bar .categories {
    height: calc(100% - 232px);
    overflow: scroll;
}

.public-side-bar .logo {
    background-color: white;
    text-align: center;
    padding: 20px 0;
}
.public-side-bar .menu-title {
    font-size: 40px;
    text-align: center;
    border-bottom: 1px solid white;
    padding: 20px 0;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.content-details {
    background-color: #eee;
    flex: 9;
    padding: 15px;
}

.public-side-bar h3 {
    color: #fff;
    min-width: 200px;
    font-size: 14px;
    font-size: 20px;
    padding: 20px 30px;
    font-weight: bold;
    border-bottom: 1px solid white;
    cursor: pointer;
}



@media (max-width: 800px) {
    /* .public-side-bar {
        overflow-x: hidden;
    } */

    .show-hide {
        z-index: 10;
        position: fixed;
        top: 2px;
        left: 260px;
        font-size: 30px;
        cursor: pointer;
    }

    .public-side-bar {
        position: fixed;
        box-shadow: 5px 5px 10px #444;
    }

    .content-details {
        flex: 1;
        width: 100vw;
        position: absolute;
        left: -300px;
        top: 0;
        margin: 0;
        padding: 0;
    }
    .public-menu-bar {
        margin-left: 50px;
    }
}
/**************header**************/

.logo {
    flex: 1;
}
.logo img {
    height: 100px;
}

.search-bar-wrapper {
    flex: 8;
    background-color: #ede9e9;
    display: flex;
    padding: 10px;
    border-radius: 15px;
}
.search-bar-wrapper .search-bar {
    width: auto;
    flex: 1;
    background-: #ede9e9;
    padding: 0 10pxcolor;
}

.username {
    flex: 1;
}

/**************side-bar*******************/

.side-stats {
    display: flex;
    color: var(--side-bar-text-color);
    /* padding: 35px 15px; */
    justify-content: center;
    /* gap: 10px; */
    align-items: center;
    cursor: pointer;
    text-align: left;
    background: #061457;
}

.side-stats:hover {
    background-color: #061457;
}

.side-stats .icon {
    font-size: 30px;
    flex: 1;
    display: none;
}
.side-stats .desc {
    min-width: 200px;
    font-size: 14px;
    max-width: 160px;
    font-size: 14px;
    padding: 5px 30px;
    /* margin: 0 30px; */
    font-weight: 200px;
    border-bottom: 1px solid white;
}
.side-stats .desc:hover {
    /* font-weight: bold; */
    background-color: #2b3379;
}
.side-stats .selected .desc {
    border-bottom-width: 3px;
}

.side-stats .quant {
    flex: 1;
    font-size: 30px;
    display: none;
}

/**************content-details*****************/

._card {
    background-color: var(--card-color);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: auto;
}

._card-header {
    border-bottom: 1px solid #e2e0e0;
    font-weight: bold;
    padding: 20px 10px;
    color: var(--theme-color);
}

._card-body .process-detail {
    margin: 5px 0;
}
._card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

/**************content-details*****************/
.defendant-list {
    border-bottom: 2px solid #e2e0e0;
    padding: 10px 0;
}
.defendant-list a:hover {
    color: #061457;
}
.defendant-list a span {
    text-decoration: underline;
}

.case-details {
    padding: 10px 0;
}

.faze-list {
    font-size: 22px;
    font-weight: bold;
}

.faze-list .item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    transition: 0.5s ease all;
    color: #061457;

}
.faze-list > div {
    cursor: pointer;
}
.faze-list .important-cases {
    color: rgb(198, 37, 37);
}

.faze-list .item:hover {
    background-color: #eee;
}

.back-to-list {
    margin-top: 50px;
    padding: 3px;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid gray;
    width: 300px;
    cursor: pointer;
}

/**************content-details*****************/

.popup-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: #fff4;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.popup-body {
    background-color: white;
    width: 80%;
    max-width: 300px;
    max-height: 90vh;
    overflow: scroll;
    box-shadow: 1px 1px 14px 14px #9f9f9f;
    padding: 20px;
    border: 2px solid var(--theme-color);
}

.popup-body .title {
    color: #061457;
    padding: 20px 0;
}
.popup-body .provinces {
    overflow: scroll;
    max-height: 60vh;
}

.popup-body .province {
    border-bottom: 1px solid var(--main-text-color);
    margin-bottom: 10px;
    text-align: center;
}

.popup-body .province:hover {
    color: var(--theme-color);
    cursor: pointer;
    font-weight: bold;
}

.case-legend {
    font-size: 12px;
    cursor: pointer;
}
.case-legend:hover {
    text-decoration: underline;
}

.case-legend .line {
    width: 20px;
    height: 10px;
    font-weight: bold;
    margin-right: 5px;
}

.public-menu-bar {
    background-color: var(--side-bar);
    color: white;
    margin-bottom: 10px;
    padding: 10px;
}

.public-menu {
}
.public-menu-item {
    cursor: pointer;
    border-right: 2px solid white;
}

@media (max-width: 1080px) {
    .provinces-list {
        display: none;
    }
}


/**************loading*****************/

.load-wrapp{
    /* background-color: royalblue; */
}
.load-wrapp .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #061457;
    margin: 3px;
  }

  
@keyframes loadingC {
    0% {
        transform: translate(0, 0);
    }
    
    50% {
        transform: translate(0, 15px);
    }
    
    100% {
        transform: translate(0, 0);
    }
}

  .load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
  }
  .load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
  }
  .load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
  }

  
.loading-container{
    position: fixed;
    top: 0;
    left:  0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffc9;
    display: flex;
    color: #061457;
    justify-content: center;
    align-items: center;
    /* From https://css.glass */
/* background: rgba(255, 255, 255, 0.2); */
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.loading-container .loading-animation{
    width: 200px;
    height: 200px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 30px;
}

.loading-container{

}

