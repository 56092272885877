:root {
    --sidebar-color: white;
    --header-height: 70px;
    --sidebar-width: 260px;
    --sidebar-minimized: 60px;
    --main-color: #4a4b52;
    --body-color: #f4f5fa;
    --transition-delay: 0.3s;
    --action-color: #e3642f;
    --orange-color: #e4571b;
    --green-color: #00a94f;
    --blue-color: #2463eb;
    --red-color: #e22f2f;
    --margin: 10px;
    --font-color: #282e52;
}

.card {
    background-color: white;
    margin: 10px;
    /* box-shadow: 1px 2px 10px 2px rgba(0,0,0,0.1); */
    border-radius: 8px;
    /* width: 100%; */
    flex: 1;
    min-height: 20px;
}
.card-header {
    padding: 15px;
    font-size: 20px;
}

.card-header h3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.card-small {
    width: 200px;
}

.card-medium {
    width: 400px;
}

.card-large {
    width: 600px;
}

.card-extra-large {
    width: 100%;
}

.no-style {
    background: none;
    box-shadow: none;
}
/***************Table*****************/

.table-responsive {
    position: relative;
    flex: 1;
    display: flex;
}

.table-responsive table {
    /* width: 100%; */
    flex: 1;
    text-align: left;
    margin: 0 15px;
}

.table-responsive table thead {
    background-color: rgb(252, 252, 252);
}
.table-responsive table th {
    padding: 15px 10px;
    text-align: right;
}

.table-responsive table tr {
    border-bottom: 1px solid hsl(203, 78%, 86%);
    transition: var(--transition-delay);
}
.table-responsive table tr:last-child {
    border-bottom: none;
}
.table-responsive table tr td {
    padding: 15px 10px;
    border-top: 1px solid rgb(241, 241, 245);
    color: var(--font-color);
    text-align: right;
    max-height: 200px;
    border: 1px solid #ececec;
}
.table-responsive table tr td .corruption-case-description {
}
.table-responsive table tr td .defendant-name {
    display: block;
    margin: 2px;
    border: 1px solid gray;
    border-radius: 3px;
}
.table-responsive table tr:hover td {
    color: var(--action-color);
}
.table-responsive table tr:nth-child(even) {
}
.table-responsive table tr:hover {
    background-color: rgb(245, 245, 245);
    color: var(--action-color);
    transition: var(--transition-delay);
    transform: scale(1.01);
}
/********Button Styles********/
.button {
    background: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 12px 1px rgb(180, 178, 178);
    font-weight: bold;
    cursor: pointer;
    transition: var(--transition-delay);
    color: white;
    margin: 0 var(--margin);
}
.button:hover {
    transform: scale(1.03);
    transition: var(--transition-delay);
}
.button-primary {
    background-color: var(--blue-color);
    color: white;
}

.button-success {
    background-color: var(--green-color);
}

.button-danger {
    background-color: var(--red-color);
}

.button-warning {
    background-color: var(--orange-color);
}

/********Stats Card********/

.stats-card-list{
    /* background-color: rebeccapurple; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.stats-card {
    display: flex;
    border: 1px solid rgb(216, 216, 216);
    background-color: white;
    margin: var(--margin);
    /* box-shadow: 1px 2px 10px 2px rgba(0,0,0,0.1); */
    border-radius: 8px;
    flex: 1;
    min-width: 200px;
    min-height: 20px;
    padding: 25px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.stats-card .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.stats-card .icon i {
    color: #2463eb;
    font-size: 40px;
}
.stats-card .stats-body {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: right;
}

.stats-card .stats-body .desc {
    font-size: 15px;
    color: var(--font-color);
}

.stats-card .stats-body .number {
    font-size: 26px;
    font-weight: bold;
    color: var(--font-color);
}

/********Form Group********/
.form-group {
    /* background-color: #00a94f; */
    flex: 1;
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin: var(--margin);
    margin-bottom: 20px;
}

.form-group label {
    flex: 1;
    font-size: 14px;
}

.form-group input,
select {
    flex: 1;
    padding: 6px;
    border-radius: 5px;
    color: var(--main-color);
    font-size: 18px;
    border: 2px solid #2b28522a;
    background-color: #282e5209;
}

.form-group input:focus,
select:focus {
    border-color: var(--action-color);
    background-color: white;
}
.form-group .radio-wrapper {
    display: flex;
    position: relative;
    display: 'flex';
    flex-wrap: 'nowrap';
    flex-direction: 'row';
    gap: 10px;
}

.form-group input[type='radio'] {
    opacity: 0;
    display: none;
}

.form-group input[type='radio'] + label {
    display: block;
    border: 2px solid #2b285211;
    padding: 5px;
    border-radius: 5px;
    color: #2b2852a1;
    cursor: pointer;
}
.form-group input[type='radio']:checked + label {
    border-color: var(--action-color);
    color: var(--action-color);
}

.form-group .error {
    color: red;
    font-size: 16px;
}

.form-group button {
    width: auto;
}

.form-group .button {
    width: fit-content;
}

.form-group input[type='file'] {
    border-style: dashed;
    padding: 20px;
}

/********Page Title********/

.page-title {
    flex: 1;
    margin: 15px;
    font-size: 25px;
    font-weight: 600;
    color: var(--font-color);
}

/********Footer card********/

.footer-card {
    flex: 1;
    display: flex;
    gap: 5px;
    flex-direction: row;
    /* margin: var(--margin); */
    margin-bottom: 20px;
    justify-content: space-between;
}
